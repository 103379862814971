


export class BrowserAppEvaluationToolInfo {

  public version = '2024.5.3.1';

  constructor() {
  }

}
